import {LitElement, html} from 'lit'
import {anchorRoute} from '../Router'
// import Element from '../Element'


customElements.define('c-error', class Features extends LitElement {
  constructor(){
    super()    
  } 

  firstUpdated(){
    super.firstUpdated()
  }

  backToMain(){
    const mainTab = document.getElementById('main-tab')
    const statusTab = document.getElementById('status-tab')
    mainTab.classList.remove('hidden')
    statusTab.innerHTML = ''
  }

  render(){    
    return html`
      <style>      
        * { box-sizing: border-box; margin: 0px; padding: 0px; }
        .outer-box { text-align: center; }
        .mi-text { margin: 30px auto;  width: 100%; font-size: 1.5em; 
          font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; }
        .exclaim{ color: red; font-size: 8em; margin: auto; }
        .action-btn {
          width: 200px;
          display: inline-block;
          background-color: var(--action-color);
          color: white;
          border-radius: 500px; 
          border: none;
          font-size: 1.3em;
          padding: .4em;
          transition: all .3s;
          margin: 10px;
        }
        .action-btn:hover { box-shadow: 0px 0px 4px rgba(0,0,0,0.5); }

        @media all and (max-width: 450px){ 
          .action-btn {
            width: 180px;
            font-size: 1.1em;
            padding: .3em;
          }
          .mi-text {  font-size: 1.2em; }
          .exclaim{ font-size: 6em; }
        }
      </style>

      <div class="outer-box">
        <p class="mi-text">
          Something went wrong
        </p>
        <p class="exclaim">!</p>
        <br>
        <button class="action-btn" @click=${this.backToMain.bind(this)}>Back to Message</button>
        <br>
        <a href="/" @click="${anchorRoute}">
          <button class="action-btn">Home</button>
        </a>  
      </div>

    `
  }
})
