import App from '../../App'
import {render, html} from 'lit/html.js'
import { anchorRoute } from '../../Router'

class HomeView {
  init(){    
    console.log('Home View.init')
    document.title = 'Peta'    
    this.render() 
  }

  nextSection(e){
    e.preventDefault()
    const thisSection = e.target.closest('section').getAttribute("id")
    const lastChar = thisSection.slice(-1)
    const num = Number(lastChar) + 1
    const nextSectionId = 'section-' + num
    const nextSection = document.getElementById(nextSectionId)
    nextSection.scrollIntoView( { behavior: 'smooth'})
  }

  backtoTop(e){
    e.preventDefault()
    const myDiv = document.getElementById('contentpanelid');
    myDiv.scrollTo( {top: 0, behavior: 'smooth'})
  }

  render(){
    const template = html`
     
      <div id="contentpanelid" class="content-panel">  
      <main-app-header pagename="${document.title}"></main-app-header>
      <main>
        <section id="section-1" class="v-center">
          <div class="h-center"> 
            <div id="logo-holder">
              <svg id="peta-cover" alt="Peta logo main" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                  height="100%" viewBox="0 0 240.333 143.333" enable-background="new 0 0 240.333 143.333"
                  xml:space="preserve">
                <g id="Layer_1">
                </g>
                <g id="side_details">
                  <g>
                    <g>
                      <g>
                        <g>
                          <path fill="#0BA998" d="M57.25,64.628c0.447,0.463,1.254,0.965,2.234,1.433c0.969,0.461,2.063,0.869,3.199,1.21
                            c2.279,0.685,4.729,1.12,7.191,1.292c4.921,0.342,9.96-0.361,14.342-2.5c4.399-2.104,8.033-5.791,10.353-10.477
                            c2.345-4.672,3.458-10.125,3.804-15.635c0.043-0.689,0.072-1.383,0.099-2.076c-0.013-0.662-0.056-1.406-0.118-2.062
                            c-0.123-1.349-0.316-2.718-0.617-4.051c-0.591-2.674-1.531-5.272-2.841-7.685c-2.603-4.837-6.685-8.843-11.495-11.582
                            C78.594,9.75,73.085,8.184,67.514,8.023c-2.782-0.081-5.587,0.222-8.277,0.967c-2.682,0.76-5.258,2.004-7.391,3.839
                            c2.084-1.888,4.644-3.209,7.328-4.043c2.693-0.821,5.525-1.202,8.347-1.191c5.646,0.021,11.292,1.462,16.298,4.151
                            c5,2.684,9.347,6.716,12.201,11.707c1.43,2.484,2.488,5.184,3.177,7.969c0.35,1.396,0.588,2.805,0.755,4.242
                            c0.098,0.738,0.121,1.397,0.195,2.135c0.085,0.705,0.16,1.414,0.223,2.129c0.486,5.676,0.251,11.592-1.61,17.355
                            c-0.927,2.871-2.296,5.674-4.141,8.215c-1.835,2.544-4.153,4.796-6.776,6.608c-5.273,3.648-11.557,5.507-17.816,6.026
                            c-3.148,0.258-6.319,0.194-9.532-0.252c-1.61-0.223-3.233-0.548-4.901-1.035c-1.649-0.492-3.436-1.139-5.277-2.305
                            L57.25,64.628z"/>
                        </g>
                        <g>
                          <path fill="#43C0B9" d="M82.223,54.2l-6.506-12.285l9.092-6.38c0,0-3.791-2.949-5.045-3.774
                            c-2.512-1.655-5.177-3.098-7.93-4.323c-2.729-1.211-5.59-2.166-8.506-2.857c-2.9-0.685-5.943-1.15-8.801-1.226l0.098-4.839
                            c3.422,0.127,6.615,0.668,9.822,1.447c3.184,0.79,6.3,1.856,9.301,3.222c2.972,1.357,5.832,2.94,8.553,4.77
                            c1.357,0.914,2.678,1.887,3.954,2.933c1.294,1.072,5.421,5.339,5.421,5.339l-10.74,7.303L82.223,54.2z"/>
                        </g>
                        <g>
                          <path fill="#0BA998" d="M54.553,23.421c1.186,5.689,2.074,9.215,2.662,15.059c0.209,2.922,0.268,5.83,0.336,8.74
                            c0.039,2.145,0.063,6.428,0.063,6.428l11.896-6.65l5.028,11.544l7.569-4.544l2.23,4.293l-9.793,4.324l-1.754,0.772l-0.65-1.647
                            l-4.15-10.512l-13.068,6.885c0,0,0.483-13.794,0.424-19.554C55.389,32.811,55.125,29.197,54.553,23.421z"/>
                        </g>
                        <g>
                          <path fill="#43C0B9" d="M42.432,10.238c2.723,6.317,4.16,13.195,5.031,20.084c0.85,6.905,1.094,13.891,0.961,20.863
                            c-0.308,13.959-2.035,27.891-5.065,41.695l-11.593-3.455c4.732-12.447,8.366-25.495,10.637-38.798
                            c1.113-6.653,1.873-13.382,2.04-20.153C44.583,23.718,44.194,16.88,42.432,10.238z"/>
                        </g>
                      </g>
                      <path fill="#43C0B9" d="M186.051,53.659c-0.621-0.521-1.504-0.781-2.65-0.781c-1.099,0-2.102,0.087-3.008,0.261
                        c-0.239-0.954-0.537-1.616-0.895-1.985c-0.358-0.368-1.063-0.554-2.113-0.554c-3.294,0-6.409,0.943-9.346,2.832
                        c-2.936,1.887-5.288,4.383-7.054,7.485c-1.668,2.929-2.538,6.024-2.631,9.283c-1.019,0.938-1.96,1.727-2.812,2.336
                        c-1.575,1.129-3.391,1.692-5.442,1.692c-2.531,0-4.381-0.912-5.551-2.734s-1.754-4.989-1.754-9.503
                        c0-1.215,0.047-2.907,0.143-5.077h8.737c5.395,0,8.093-1.498,8.093-4.492c0-0.823-0.168-1.388-0.502-1.691
                        s-0.884-0.456-1.646-0.456h-8.021c6.206-4.989,9.311-10.849,9.311-17.575c0-2.43-0.573-4.448-1.719-6.054
                        c-1.146-1.605-2.722-2.409-4.727-2.409c-2.722,0-5.264,1.172-7.627,3.515s-4.381,5.49-6.052,9.439
                        c-1.672,3.949-2.912,8.311-3.724,13.083h-4.082c-2.148,0-3.223,1.216-3.223,3.646c0,1.041,0.251,1.801,0.752,2.277
                        c0.501,0.479,1.468,0.717,2.9,0.717h2.793c-0.144,1.953-0.215,3.884-0.215,5.793c0,1.407,0.071,2.723,0.195,3.97
                        c-0.428,0.14-0.829,0.391-1.198,0.782c-2.004,2.213-4.381,4.003-7.125,5.369c-2.746,1.367-5.191,2.051-7.34,2.051
                        c-3.344,0-5.611-0.998-6.805-2.994c5.634-1.258,9.633-2.961,11.996-5.109s3.545-4.719,3.545-7.713
                        c0-2.561-0.836-4.59-2.506-6.087c-1.672-1.497-3.893-2.245-6.66-2.245c-3.104,0-5.885,0.76-8.344,2.277
                        c-2.459,1.52-4.381,3.57-5.766,6.152s-2.076,5.369-2.076,8.363c0,4.297,1.385,7.682,4.154,10.154
                        c2.768,2.475,6.66,3.711,11.672,3.711c3.676,0,7.127-0.824,10.35-2.474s5.717-3.45,7.482-5.403
                        c0.06-0.063,0.101-0.142,0.157-0.208c2.194,5.377,6.477,8.084,12.877,8.084c3.15,0,5.943-0.738,8.379-2.213
                        c1.013-0.613,1.984-1.315,2.931-2.072c0.287,0.479,0.605,0.934,0.972,1.355c1.694,1.953,3.903,2.93,6.624,2.93
                        c3.628,0,6.183-1.865,7.663-5.598l-0.071,1.041c0,1.605,0.394,2.767,1.182,3.482c0.787,0.716,1.992,1.074,3.616,1.074
                        c1.671,0,2.876-0.358,3.616-1.074s1.11-1.898,1.11-3.547c0-2.995,0.144-5.696,0.43-8.104c0.286-2.409,0.691-4.937,1.218-7.583
                        c0.477-2.475,0.716-4.209,0.716-5.208C186.981,54.918,186.67,54.18,186.051,53.659z M145.624,39.697
                        c0.788-2.886,1.623-5.143,2.507-6.77c0.883-1.627,1.683-2.441,2.399-2.441c1.05,0,1.575,1.28,1.575,3.84
                        c0,2.257-0.705,4.763-2.112,7.518c-1.409,2.757-3.521,5.24-6.338,7.453C144.18,45.783,144.837,42.584,145.624,39.697z
                        M110.57,66.678c0-2.646,0.607-4.903,1.826-6.77c1.217-1.865,2.709-2.799,4.475-2.799c0.859,0,1.541,0.25,2.041,0.748
                        c0.502,0.5,0.752,1.162,0.752,1.985c0,1.606-0.812,3.017-2.434,4.231c-1.625,1.215-3.844,2.127-6.66,2.733V66.678z
                        M176.884,63.228c-0.859,4.34-1.839,7.355-2.937,9.048c-1.099,1.692-2.316,2.539-3.652,2.539c-0.907,0-1.636-0.336-2.184-1.01
                        c-0.55-0.672-0.824-1.659-0.824-2.961c0-2.43,0.502-4.729,1.504-6.9c1.003-2.169,2.339-3.916,4.011-5.24
                        c1.671-1.322,3.484-2.006,5.442-2.05L176.884,63.228z"/>
                    </g>
                    <g>
                      <path fill="#B2B3B3" d="M4.163,113.371c0.95-0.094,1.88-0.164,2.79-0.211c0.909-0.046,1.949-0.07,3.123-0.07
                        c1.818,0,3.385,0.263,4.699,0.786c1.313,0.524,2.404,1.245,3.273,2.162c0.869,0.918,1.512,1.984,1.926,3.201
                        s0.621,2.509,0.621,3.874c0,1.367-0.211,2.663-0.637,3.889c-0.424,1.227-1.076,2.299-1.955,3.215
                        c-0.879,0.918-2.001,1.643-3.365,2.177c-1.364,0.533-2.997,0.8-4.896,0.8c-1.152,0-2.158-0.023-3.018-0.07
                        s-1.713-0.117-2.562-0.211V113.371z M9.984,115.393c-0.586,0-1.121,0.014-1.606,0.042s-0.931,0.061-1.335,0.099v15.189
                        c0.426,0.056,0.869,0.094,1.335,0.112c0.464,0.019,0.95,0.028,1.455,0.028c1.354,0,2.526-0.192,3.517-0.576
                        c0.99-0.383,1.805-0.917,2.441-1.601c0.637-0.683,1.106-1.497,1.41-2.442c0.303-0.944,0.454-1.988,0.454-3.131
                        c0-1.011-0.151-1.979-0.454-2.905c-0.304-0.927-0.77-1.75-1.395-2.471c-0.627-0.721-1.426-1.292-2.396-1.713
                        C12.44,115.604,11.299,115.393,9.984,115.393z"/>
                      <path fill="#B2B3B3" d="M25.902,115.87c-0.525,0-0.976-0.173-1.35-0.52s-0.561-0.763-0.561-1.25c0-0.542,0.172-0.973,0.516-1.291
                        s0.809-0.478,1.395-0.478c0.525,0,0.975,0.174,1.35,0.52c0.373,0.347,0.561,0.763,0.561,1.249c0,0.544-0.172,0.974-0.516,1.292
                        S26.488,115.87,25.902,115.87z M27.267,132.969h-2.729v-14.6h2.729V132.969z"/>
                      <path fill="#B2B3B3" d="M38.363,118.032c0.91,0,1.723,0.14,2.44,0.421c0.718,0.28,1.339,0.627,1.864,1.039v-1.123h2.486v14.263
                        c0,1.217-0.172,2.255-0.516,3.116s-0.833,1.567-1.47,2.12c-0.637,0.552-1.399,0.959-2.289,1.222
                        c-0.89,0.262-1.88,0.393-2.972,0.393c-0.95,0-1.859-0.103-2.729-0.309s-1.647-0.515-2.334-0.927l1-2.189
                        c0.484,0.354,1.051,0.649,1.697,0.884s1.436,0.352,2.365,0.352c1.436,0,2.563-0.347,3.381-1.039s1.229-1.816,1.229-3.369v-1.797
                        c-0.405,0.449-0.986,0.875-1.744,1.277s-1.662,0.604-2.713,0.604c-0.891,0-1.76-0.164-2.607-0.491
                        c-0.85-0.327-1.598-0.805-2.244-1.432s-1.168-1.395-1.562-2.303c-0.394-0.907-0.591-1.951-0.591-3.131
                        c0-1.085,0.191-2.092,0.576-3.018c0.383-0.927,0.904-1.728,1.561-2.401c0.657-0.674,1.43-1.202,2.32-1.586
                        C36.402,118.224,37.352,118.032,38.363,118.032z M38.666,130.723c0.809,0,1.536-0.169,2.184-0.506
                        c0.646-0.337,1.172-0.729,1.576-1.179v-7.637c-0.465-0.3-1-0.571-1.607-0.814c-0.605-0.243-1.334-0.365-2.182-0.365
                        c-0.627,0-1.229,0.117-1.805,0.351c-0.576,0.234-1.087,0.576-1.531,1.025c-0.445,0.449-0.799,0.997-1.061,1.643
                        c-0.264,0.646-0.395,1.39-0.395,2.232c0,0.805,0.131,1.529,0.395,2.176c0.262,0.646,0.615,1.198,1.061,1.656
                        c0.444,0.459,0.955,0.81,1.531,1.053S38.02,130.723,38.666,130.723z"/>
                      <path fill="#B2B3B3" d="M51.219,115.87c-0.525,0-0.976-0.173-1.35-0.52s-0.561-0.763-0.561-1.25c0-0.542,0.172-0.973,0.516-1.291
                        s0.809-0.478,1.395-0.478c0.525,0,0.975,0.174,1.35,0.52c0.373,0.347,0.561,0.763,0.561,1.249c0,0.544-0.172,0.974-0.516,1.292
                        S51.805,115.87,51.219,115.87z M52.583,132.969h-2.729v-14.6h2.729V132.969z"/>
                      <path fill="#B2B3B3" d="M58.677,114.298h2.093v4.604h4.365v2.189H60.77v7.048c0,0.543,0.064,1.001,0.196,1.376
                        c0.132,0.374,0.308,0.674,0.53,0.898s0.49,0.389,0.805,0.491c0.313,0.103,0.641,0.154,0.984,0.154
                        c0.506,0,0.98-0.074,1.426-0.225c0.443-0.149,0.868-0.327,1.273-0.533l0.758,2.021c-0.404,0.225-0.951,0.444-1.638,0.659
                        s-1.405,0.323-2.152,0.323c-1.517,0-2.714-0.458-3.593-1.376c-0.879-0.917-1.319-2.246-1.319-3.986v-6.852h-2.729v-2.189h2.79
                        L58.677,114.298z"/>
                      <path fill="#B2B3B3" d="M67.803,125.753c0-1.085,0.183-2.101,0.547-3.046c0.363-0.945,0.873-1.765,1.531-2.457
                        c0.656-0.692,1.444-1.235,2.364-1.629c0.919-0.393,1.925-0.589,3.017-0.589c0.91,0,1.732,0.145,2.471,0.435
                        c0.738,0.291,1.38,0.65,1.926,1.081v-1.179h2.486v11.062c0,0.712,0.187,1.175,0.561,1.391c0.374,0.215,0.783,0.322,1.229,0.322
                        l-0.576,1.966c-1.941,0-3.113-0.73-3.518-2.19c-0.242,0.3-0.521,0.595-0.834,0.885c-0.313,0.29-0.672,0.547-1.076,0.771
                        s-0.859,0.402-1.364,0.534c-0.505,0.131-1.062,0.196-1.667,0.196c-0.991,0-1.916-0.178-2.774-0.533
                        c-0.859-0.355-1.606-0.861-2.243-1.517s-1.143-1.445-1.517-2.372S67.803,126.914,67.803,125.753z M75.504,131.06
                        c0.769,0,1.496-0.169,2.184-0.506s1.263-0.776,1.729-1.319v-7.833c-0.546-0.355-1.148-0.642-1.805-0.856s-1.35-0.323-2.076-0.323
                        c-0.729,0-1.395,0.131-2.002,0.394c-0.605,0.262-1.127,0.631-1.561,1.108c-0.436,0.478-0.773,1.053-1.016,1.727
                        c-0.243,0.674-0.364,1.414-0.364,2.219c0,0.786,0.121,1.512,0.364,2.176c0.242,0.665,0.586,1.235,1.03,1.713
                        s0.966,0.847,1.562,1.108C74.145,130.929,74.797,131.06,75.504,131.06z"/>
                      <path fill="#B2B3B3" d="M90.148,128.14c0,1.011,0.227,1.76,0.682,2.246c0.455,0.487,1.086,0.729,1.895,0.729
                        c0.385,0,0.759-0.042,1.123-0.126c0.363-0.084,0.687-0.183,0.97-0.295l0.667,2.05c-0.363,0.15-0.814,0.281-1.35,0.394
                        s-1.127,0.168-1.773,0.168c-0.707,0-1.359-0.094-1.955-0.28c-0.597-0.188-1.117-0.478-1.563-0.871
                        c-0.444-0.393-0.793-0.898-1.045-1.516c-0.254-0.618-0.379-1.366-0.379-2.246v-16.201h2.729V128.14z"/>
                      <path fill="#B2B3B3" d="M107.096,129.066c0.729,0.562,1.547,1.021,2.457,1.375c0.909,0.356,1.971,0.534,3.184,0.534
                        c1.455,0,2.607-0.281,3.456-0.843s1.272-1.319,1.272-2.274c0-0.393-0.061-0.758-0.182-1.095s-0.359-0.66-0.712-0.969
                        c-0.354-0.309-0.849-0.617-1.485-0.927c-0.637-0.309-1.461-0.641-2.472-0.996c-1.091-0.394-2.017-0.781-2.774-1.165
                        s-1.369-0.805-1.834-1.264s-0.799-0.964-1-1.517c-0.203-0.552-0.304-1.184-0.304-1.895c0-0.692,0.156-1.349,0.47-1.966
                        c0.313-0.618,0.778-1.155,1.395-1.614c0.617-0.459,1.375-0.819,2.274-1.081s1.925-0.394,3.077-0.394
                        c1.254,0,2.375,0.117,3.365,0.352s1.971,0.566,2.941,0.996l-1.061,2.274c-0.688-0.374-1.492-0.688-2.411-0.94
                        c-0.92-0.253-1.885-0.379-2.896-0.379c-1.334,0-2.385,0.238-3.152,0.716c-0.77,0.478-1.152,1.109-1.152,1.896
                        c0,0.374,0.055,0.706,0.166,0.996c0.111,0.291,0.334,0.571,0.668,0.843c0.333,0.271,0.797,0.548,1.395,0.828
                        c0.596,0.281,1.389,0.59,2.379,0.927c1.173,0.412,2.163,0.833,2.972,1.264s1.456,0.889,1.94,1.375
                        c0.485,0.487,0.834,1.011,1.047,1.573c0.211,0.562,0.318,1.179,0.318,1.853c0,0.917-0.193,1.731-0.576,2.442
                        c-0.385,0.712-0.916,1.315-1.592,1.812c-0.678,0.496-1.491,0.87-2.441,1.123s-1.98,0.379-3.092,0.379
                        c-1.477,0-2.82-0.183-4.033-0.548c-1.213-0.364-2.233-0.847-3.063-1.445L107.096,129.066z"/>
                      <path fill="#B2B3B3" d="M123.166,125.669c0-1.18,0.201-2.241,0.605-3.187s0.955-1.746,1.652-2.401
                        c0.697-0.654,1.526-1.16,2.486-1.516s1.996-0.533,3.107-0.533c1.092,0,2.117,0.178,3.078,0.533
                        c0.959,0.355,1.793,0.861,2.501,1.516c0.708,0.655,1.263,1.451,1.668,2.387c0.403,0.937,0.606,1.984,0.606,3.145
                        c0,1.161-0.197,2.214-0.592,3.159s-0.945,1.755-1.652,2.429s-1.541,1.193-2.501,1.558c-0.96,0.365-1.997,0.548-3.108,0.548
                        c-1.132,0-2.178-0.188-3.138-0.562s-1.788-0.898-2.485-1.572s-1.244-1.479-1.638-2.415
                        C123.362,127.821,123.166,126.792,123.166,125.669z M136.082,125.669c0-0.73-0.127-1.423-0.379-2.078
                        c-0.254-0.655-0.607-1.226-1.063-1.713c-0.454-0.486-0.99-0.874-1.606-1.165c-0.616-0.29-1.288-0.435-2.017-0.435
                        c-1.597,0-2.84,0.486-3.729,1.46c-0.891,0.973-1.334,2.265-1.334,3.874c0,0.73,0.125,1.428,0.379,2.092
                        c0.252,0.665,0.605,1.245,1.061,1.741s0.99,0.889,1.607,1.179c0.616,0.291,1.289,0.436,2.016,0.436
                        c0.789,0,1.496-0.131,2.123-0.394c0.626-0.262,1.156-0.631,1.592-1.108c0.434-0.478,0.768-1.044,1-1.699
                        C135.965,127.204,136.082,126.474,136.082,125.669z"/>
                      <path fill="#B2B3B3" d="M145.086,128.14c0,1.011,0.227,1.76,0.682,2.246c0.455,0.487,1.086,0.729,1.895,0.729
                        c0.385,0,0.759-0.042,1.123-0.126c0.363-0.084,0.687-0.183,0.97-0.295l0.667,2.05c-0.363,0.15-0.814,0.281-1.35,0.394
                        s-1.127,0.168-1.773,0.168c-0.707,0-1.359-0.094-1.955-0.28c-0.597-0.188-1.117-0.478-1.563-0.871
                        c-0.444-0.393-0.793-0.898-1.045-1.516c-0.254-0.618-0.379-1.366-0.379-2.246v-16.201h2.729V128.14z"/>
                      <path fill="#B2B3B3" d="M166.643,133.109c-0.971,0-1.738-0.178-2.305-0.534c-0.565-0.354-0.96-0.87-1.182-1.544
                        c-0.708,0.674-1.521,1.222-2.441,1.643s-1.925,0.632-3.017,0.632c-0.687,0-1.349-0.099-1.985-0.295s-1.203-0.51-1.698-0.94
                        s-0.89-0.992-1.183-1.685s-0.439-1.535-0.439-2.527v-9.489h2.729v9.152c0,1.143,0.258,2.018,0.773,2.626s1.289,0.912,2.319,0.912
                        c0.849,0,1.657-0.154,2.426-0.463c0.768-0.31,1.455-0.726,2.062-1.25v-10.978h2.729v10.81c0,0.411,0.051,0.744,0.152,0.996
                        c0.101,0.253,0.231,0.449,0.394,0.59c0.161,0.141,0.354,0.238,0.576,0.295c0.222,0.056,0.444,0.084,0.667,0.084L166.643,133.109z
                        "/>
                      <path fill="#B2B3B3" d="M172.1,114.298h2.092v4.604h4.366v2.189h-4.366v7.048c0,0.543,0.066,1.001,0.197,1.376
                        c0.131,0.374,0.309,0.674,0.531,0.898c0.222,0.225,0.489,0.389,0.803,0.491s0.642,0.154,0.986,0.154
                        c0.504,0,0.979-0.074,1.425-0.225c0.444-0.149,0.868-0.327,1.273-0.533l0.758,2.021c-0.405,0.225-0.95,0.444-1.638,0.659
                        s-1.404,0.323-2.152,0.323c-1.516,0-2.714-0.458-3.594-1.376c-0.879-0.917-1.318-2.246-1.318-3.986v-6.852h-2.729v-2.189h2.789
                        L172.1,114.298z"/>
                      <path fill="#B2B3B3" d="M184.258,115.87c-0.526,0-0.976-0.173-1.35-0.52s-0.561-0.763-0.561-1.25
                        c0-0.542,0.172-0.973,0.516-1.291c0.343-0.318,0.808-0.478,1.395-0.478c0.525,0,0.975,0.174,1.349,0.52
                        c0.374,0.347,0.562,0.763,0.562,1.249c0,0.544-0.172,0.974-0.516,1.292S184.844,115.87,184.258,115.87z M185.622,132.969h-2.729
                        v-14.6h2.729V132.969z"/>
                      <path fill="#B2B3B3" d="M189.261,125.669c0-1.18,0.202-2.241,0.606-3.187s0.955-1.746,1.652-2.401
                        c0.697-0.654,1.525-1.16,2.486-1.516c0.96-0.355,1.995-0.533,3.107-0.533c1.092,0,2.117,0.178,3.077,0.533
                        s1.794,0.861,2.502,1.516c0.707,0.655,1.263,1.451,1.667,2.387c0.404,0.937,0.606,1.984,0.606,3.145
                        c0,1.161-0.197,2.214-0.591,3.159c-0.395,0.945-0.945,1.755-1.652,2.429c-0.708,0.674-1.542,1.193-2.502,1.558
                        c-0.96,0.365-1.996,0.548-3.107,0.548c-1.132,0-2.178-0.188-3.138-0.562c-0.961-0.374-1.789-0.898-2.486-1.572
                        s-1.243-1.479-1.638-2.415C189.458,127.821,189.261,126.792,189.261,125.669z M202.177,125.669c0-0.73-0.127-1.423-0.379-2.078
                        c-0.253-0.655-0.606-1.226-1.062-1.713c-0.455-0.486-0.99-0.874-1.606-1.165c-0.617-0.29-1.289-0.435-2.017-0.435
                        c-1.597,0-2.84,0.486-3.729,1.46c-0.89,0.973-1.334,2.265-1.334,3.874c0,0.73,0.126,1.428,0.379,2.092
                        c0.253,0.665,0.606,1.245,1.062,1.741s0.99,0.889,1.606,1.179c0.617,0.291,1.289,0.436,2.017,0.436
                        c0.788,0,1.496-0.131,2.122-0.394c0.627-0.262,1.157-0.631,1.592-1.108s0.769-1.044,1.001-1.699
                        C202.061,127.204,202.177,126.474,202.177,125.669z"/>
                      <path fill="#B2B3B3" d="M211.241,120.812c0.565-0.786,1.288-1.446,2.168-1.979c0.879-0.533,1.925-0.8,3.138-0.8
                        c1.778,0,3.107,0.496,3.987,1.487c0.879,0.992,1.318,2.303,1.318,3.931v9.519h-2.729v-9.237c0-1.067-0.263-1.914-0.788-2.541
                        c-0.526-0.627-1.273-0.94-2.244-0.94c-0.465,0-0.92,0.08-1.364,0.238c-0.444,0.159-0.874,0.379-1.288,0.66
                        c-0.415,0.28-0.799,0.608-1.152,0.982c-0.354,0.375-0.672,0.768-0.955,1.18v9.658h-2.729v-14.6h2.638V120.812z"/>
                      <path fill="#B2B3B3" d="M226.34,129.684c0.525,0.431,1.188,0.777,1.986,1.039c0.798,0.263,1.652,0.393,2.562,0.393
                        c1.132,0,1.986-0.173,2.563-0.519c0.576-0.347,0.864-0.791,0.864-1.334c0-0.281-0.056-0.543-0.167-0.786
                        s-0.313-0.478-0.606-0.702s-0.692-0.444-1.197-0.66c-0.506-0.215-1.163-0.453-1.971-0.716c-1.496-0.468-2.607-1.024-3.335-1.67
                        c-0.729-0.646-1.092-1.54-1.092-2.682c0-0.562,0.137-1.086,0.409-1.572s0.667-0.913,1.183-1.277
                        c0.516-0.365,1.142-0.65,1.88-0.856c0.737-0.206,1.581-0.309,2.531-0.309c1.031,0,1.936,0.098,2.714,0.294
                        c0.777,0.197,1.48,0.445,2.107,0.744l-0.759,2.078c-0.545-0.3-1.167-0.543-1.864-0.73c-0.697-0.187-1.471-0.28-2.319-0.28
                        c-1.052,0-1.85,0.196-2.396,0.59c-0.546,0.393-0.818,0.823-0.818,1.291c0,0.225,0.046,0.431,0.137,0.618
                        c0.091,0.187,0.258,0.364,0.5,0.533c0.242,0.168,0.565,0.337,0.97,0.505c0.404,0.169,0.91,0.347,1.517,0.534
                        c0.99,0.318,1.829,0.641,2.517,0.969c0.687,0.327,1.243,0.688,1.667,1.081c0.425,0.393,0.728,0.818,0.91,1.277
                        c0.182,0.459,0.272,0.969,0.272,1.53c0,0.655-0.151,1.245-0.455,1.769c-0.303,0.524-0.728,0.969-1.273,1.334
                        c-0.545,0.364-1.208,0.646-1.985,0.842c-0.778,0.196-1.643,0.295-2.593,0.295c-1.213,0-2.294-0.141-3.244-0.421
                        c-0.95-0.281-1.738-0.646-2.364-1.096L226.34,129.684z"/>
                    </g>
                  </g>
                </g>
                </svg>
              <svg version="1.1" id="peta-line" alt="Line" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                width="158px" height="8.5px" viewBox="0 0 158 8.5" enable-background="new 0 0 158 8.5" xml:space="preserve">
                <g>
                  <path fill="#00D1C3" d="M3.5,4.116c12-0.932,25.356-1.166,37.955-1.463c12.6-0.213,25.277,0.149,37.876,0.325l37.584,0.487
                    l37.585,0.65V4.44l-37.545,0.65L79.284,5.578C66.686,5.754,53.991,6.116,41.393,5.903C28.793,5.606,15.5,5.372,3.5,4.44V4.116z"/>
                </g>
              </svg>
            </div>

            <button class="action-btn mobile-hide" @click="${this.nextSection}">See more ...</button>
            
          </div>
        </section>

        <section id="section-2" class="display-section">
          <div class="display-images">
            <a href="https://hashtag-webapp.com" target="_blank">
              <img class="display-img" alt="#WebApp Display"  src="/images/display-webapp.png">               
            </a>  
          </div>

          <h2>#WebApp</h2>
          <p>
            This website is designed for an exceptional "User Experience" allowing the user to interact with the site while gaining
            the information required.  This project was fullstack utilising; Nodejs, Express, Lit and Scss.
          </p>
          <ul>
            <li>
              <p><strong>Responsive Design: </strong>
                Great visuals which are appealing on a range on devices.
              </p>
            </li>
            <li>
              <p><strong>User Interactions: </strong>
                Interactive hover elements in addition to easy navigation.
              </p>
            </li>
            <li>
              <p><strong>UI/UX: </strong>
                A well designed user interface with clear status updates for an improved user experience.
              </p>
            </li>
            <li>
              <p><strong>Animation: </strong>
                Appealing yet sublte animations adding a touch of entertainment. 
                Incorporating type, scroll and SVG animations and a footer reveal.
              </p>
            </li>
            <li>
              <p><strong>Contact Form: </strong>
                A fully customized contact form with html email formatting.
              </p>
            </li>
          </ul>
          <a class="visit-btn" href="https://hashtag-webapp.com" target="_blank">Visit Site</a>
        </section>

           <!-- display-webdevpathway -->

           
        <section id="section-3" class="display-section">
          <div class="display-images">
            <a href="https://webdevpathway.hashtag-webapp.com" target="_blank">
              <img class="display-img" alt="Web Dev Display"  src="/images/display-webdevpathway.png">               
            </a>  
          </div>

          <h2>Web Dev Pathway</h2>
          <p>
            This website is a single page scrolling website designed for upcoming website developers.
            This project is a frontend "Single Page Application" (SPA) utilising; Nodejs, Lit and Scss.
          </p>
          <ul>
              <li>
                <p><strong>Dark Theme Design: </strong>
                  Appealing visuals which are also beneficial for reducing eye strain.
                </p>
              </li>
              <li>
                <p><strong>Study Tracker: </strong>
                  Stores students' progress locally to help with self-tracking.
                </p>
              </li>
              <li>
                <p><strong>UI/UX: </strong>
                  A well designed dynamic interface for an efficient smooth user experience.
                </p>
              </li>
              <li>
                <p><strong>Animations: </strong>
                  Appealing animations for enjoyable user interactions and pleasurable reward for their progress. 
                </p>
              </li>
            </ul>
            <a class="visit-btn" href="https://webdevpathway.hashtag-webapp.com" target="_blank">Visit Site</a>
          </section>

          
          <section id="section-4" class="display-section">
            <div class="display-images">
              <a href="https://hashtag-metoo.com" target="_blank">
                <img class="display-img" alt="#MeToo Display"  src="/images/display-metoo.png">               
              </a> 
            </div>

            <h2>#MeToo</h2>
            <p>
              This website exemplifies a minimilist design creating a calm, easy user experience.  This project was fullstack utilising the MELN stack; MongoDB, Nodejs, Express and Lit.
            </p>
            <ul>
              <li><p><strong>Automated emailing: </strong>
                Including email verification for signup, forgot password and forum subscriptions with unsubscribe.
                </p></li>
                <li><p><strong>3rd party API: </strong>
                  Payments powered by Stripe API.
                </p></li>
                <li><p><strong>Content Management System: </strong>
                  RESTful API's for user, feedback and forum databases.
                </p></li>
                <li><p><strong>Multi-level Access: </strong>
                  User, moderator and administration access.
                </p></li>
                <li><p><strong>Dynamic Data Features: </strong>
                  Including statistics results, quiz and leader boards.
                </p></li>
                <li><p><strong>Accessibility: </strong>
                  Adjustable font size settings
                </p></li>
            </ul>
            <a class="visit-btn" href="https://hashtag-metoo.com" target="_blank">Visit Site</a>
          </section>

          <section id="section-5" id="widget-display" class="display-section">
            <h2>Components</h2>

            <div class="grid-sml">

              <div class="item">
                
                <widget-icon widget="app-calculator">
                  <img class="widget-icon" alt="Calculator icon"  src="/images/widgets/calculator-icon.png">
                  <h4>Calculator</h4>
                  <p>BODMAS rule</p>
                  <p>Multiple calculations</p>
                  <p>Sum display</p>
                  <p>Animated clicks</p>
                </widget-icon>

              </div>
              
              <div class="item">
                <widget-icon widget="app-dark-theme">
                  <img class="widget-icon" alt="Dark Theme Icon"  src="/images/widgets/dark-theme-widget.png">
                  <h4>Theme Toggle</h4>
                  <p>SVG</p>
                  <p>Javascript</p>
                  <p>Animation</p>
                  <p>Theme sample</p>
                </widget-icon>
              </div>

              <div class="item">
                
                <widget-icon widget="app-date-picker">
                  <img class="widget-icon" alt="Date Range picker Icon"  src="/images/widgets/calendar-widget.png">
                  <h4>Date Picker</h4>
                  <p>Duration</p>
                  <p>Languages</p>
                  <p>Highlighted</p>
                  <p>Locales</p>
                </widget-icon>
              </div>
            </div>


          
          </section>

          <section class="to-top">
            <button class="totop-btn" @click="${this.backtoTop}">Back to top</button>
          </section>
        </main>


      </div> 

    `

    render(template, App.rootEl)
  }
}

export default new HomeView()